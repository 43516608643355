.loading {
    display: inline-block;
    border: 5px solid #333333;
    border-right-color: transparent;
    border-radius: 50%;
    animation: rotate 1s infinite linear;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
