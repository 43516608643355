.search-container {
    order: 1;
    position: relative;
    z-index: 2;
}
@media all and (min-width: 801px) {
    .search-container {
        margin: 10px 0.5em 0 0.5em;
    }
}
@media all and (max-width: 800px) {
    .search-container {
        margin: 0;
    }
}
.search-icon {
    z-index: 2;
    position: absolute;
    top: 2px;
    right: 10px;
    background-image: url('../img/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 32px;
}
.search-icon:hover {
    background-image: url('../img/search-blue.svg');
}
.search-input {
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    border: 2px solid rgba(0,0,0,0.2);
    padding-right: 35px;
    padding-left: 10px;
    opacity: .7;
    transition: opacity .2s;
    width: 100%;
    height: 35px;
    font-size: 15px;
}
.search-input:focus {
    outline: none;
    opacity: 1;
}