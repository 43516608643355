@media all and (min-width: 801px) {
    .app {
        display: flex;
        flex-flow: row;
        overflow: hidden;
    }
    .box-one {
        order: 1;
        flex: 0 0 20%;
        min-width: 300px;
        height: 100%;
    }
    .box-two {
        order: 2;
        flex: 1;
        overflow: hidden;
    }
}
@media all and (max-width: 800px) {
    .app {
        height: 100%;
        overflow: hidden;
    }
    .box-one {
        width: 100%;
    }
    .box-two {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
    }
}