.results-summary {
    order: 2;
    position: relative;
    min-height: 6.5em;
    border-radius: 4px;
    border: 2px solid rgba(0,0,0,0.2);
    background-color: white;
    padding-left: 0.25em;
    z-index: 2;
}
@media all and (min-width: 801px) {
    .results-summary {
        margin: 10px 0.5em 5px 0.5em;
    }
}
@media all and (max-width: 800px) {
    .results-summary {
        margin: 5px 0;
    }
}
.summary-table {
  position: absolute;
  top: 0;
  left: 0;
}
.summary-table td {
    color: rgb(78, 78, 78);
    text-align: left;
    white-space: nowrap;
    margin: 0;
    padding-right: 1em;
    height: 1em;
}
.search-loading {
    position: absolute;
    top: 1em;
    left: 46%;
}