.info-item {
    text-align: left;
    margin-bottom: 0.5em;
    border: 1px dashed lightgrey;
}
.dot {
    height: 0.75em;
    width: 0.75em;
    margin-right: 0.5em;
    border-radius: 50%;
    display: inline-block;
  }