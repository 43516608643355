.leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}
a.rating-link {
    color: black;
    font-size: 16px;
    padding: 0.25em;
    border: 2px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    box-shadow: 2px 4px rgba(0,0,0,0.2);;
}